/* eslint-disable sonarjs/no-duplicate-string */
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import AppContext from './AppContext';

function AppProvider({ children }) {
  const [jogos, setJogos] = useState({});
  const [horario, setHorario] = useState('12:00');
  const [linkSite, setLinkSite] = useState('https://fortunes777.site/?id=AF16a47&currency=BRL&type=2');
  const [estado, setEstado] = useState(null);

  console.log(estado !== 'São Paulo');

  useEffect(() => {
    const obterEstadoPorIP = async () => {
      try {
        const response = await fetch('https://ipwhois.app/json/');
        const data = await response.json();

        if (data && data.region) {
          setEstado(data.region);
        }
      } catch (error) {
        console.error('Erro ao obter localização por IP:', error);
      }
    };

    obterEstadoPorIP();
    if (estado !== 'State of São Paulo') {
      // setLinkSite('https://www.w1-2025pg.com/?id=811726374&type=2&currency=BRL');
      setLinkSite('https://fortunes777.site/?id=AF16a47&currency=BRL&type=2');
    } else {
      setLinkSite('https://fortunes777.site/?id=AF16a47&currency=BRL&type=2');
    }
  }, [estado]);

  const values = useMemo(
    () => ({
      jogos,
      setJogos,
      horario,
      setHorario,
      linkSite,
      setLinkSite,
    }),
    [
      jogos,
      horario,
      linkSite,
    ],
  );

  return (
    <AppContext.Provider value={ values }>
      {children}
    </AppContext.Provider>
  );
}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
